import React, { Component } from 'react'
import './../App.css';
import Splash from './../Components/Splash'
import Grid from './../Components/Grid'
import {Education, Experience, Activities} from '../Components/Temps'
import Monitor from './../Components/Monitor'

/**
 * Wraper for the entire home (index) page for my website. 
 */
class Home extends Component {
  
  render() {
    return (
      <div className="App">
        <Splash />
        <Education />
        <Experience />
        <Activities />
        <Monitor />
        <Grid />
      </div>
    );
  }
}

export default Home;