import React, { Component } from 'react'
import './Styling/Monitor.css';
import Anim from './Helper/Anim'
import MonitorRend from './Helper/MonitorRend'
import KeyboardRend from './Helper/KeyboardRend'

/**
 * Class which creates the Entire monitor component, screen, content and keyboard 
 */
class Monitor extends Component {
    // Make the Monitor the appropriate size
    constructor(props) {
        super(props);
        this.updateWindowDimensions();
    }

    // On mount, update size and add listener for future changes 
    componentDidMount() {
        // Update the screen size 
        this.updateWindowDimensions();

        // Monitor for changing window size 
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    // Make sure the monitor is always sized to the height of the screen 
    updateWindowDimensions() {
        document.documentElement.style.setProperty('--screen-height', `${window.innerHeight}px`);
    }

    // Clear the listener on unload, avoid memory link 
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    // Return the complete monitor, Notice the nested class is where the on monitor information arrives 
    render() {
        return (
            <div className="fullMon">
                <div className="outer">
                    <div className="colors">
                        <MonitorRend />
                    </div>
                    <div className="nested">
                        <Anim paths={
                            ["M251.51,389.28h-102v89H255.87v81.09H.38V478.23H69.26V141.64h-68V60.54H228c57.55,0,119.46,13.08,157,55.81,27,31.39,38.37,70.63,38.37,112.49C423.29,318.65,355.28,389.28,251.51,389.28Zm46.21-235.43c-20.92-9.6-48-12.21-69.75-12.21H149.49V308.19h68.88c29.65,0,60.17,0,86.33-12.21,16.57-7.85,38.37-29.65,38.37-69.76C343.07,194.83,325.63,166.93,297.72,153.85Z",
                                "M890.68,280.29c-2.62-21.8-6.11-30.52-27.91-30.52-19.18,0-40.11,9.59-57.55,19.18-35.75,20.05-67.14,44.47-98.53,70.63V478.23h158.7v81.09H532.29V478.23h93.3V262H531.41V180.88H706.69v61c43.6-34.88,95.91-73.25,156.08-73.25S949.1,200.93,957,222.73c5.23,14,10.46,28.78,10.46,45.35Z",
                                "M1261.27,569.79c-95,0-228.46-51.45-228.46-198.82,0-133.41,110.74-202.3,224.1-202.3,150,0,221.49,102,221.49,200.56C1478.4,510.49,1361.55,569.79,1261.27,569.79Zm-7-320c-77.6,0-139.52,46.21-139.52,120.33,0,95.92,93.31,118.59,144.75,118.59,68,0,137.78-36.62,137.78-121.21C1397.3,315.17,1358.06,249.77,1254.29,249.77Z",
                                "M1723.42,719.77c-44.47,0-88.07-8.72-130.8-17.44l19.19-81.1c35.75,9.6,72.37,17.44,111.61,17.44,27.91,0,58.43,0,72.38-34.88,7-18.31,7.85-40.11,7.85-61V262h-211.9V180.88h293V547.11C1884.74,599.44,1883.87,719.77,1723.42,719.77Zm55.81-589.47V.38h91.56V130.3Z",
                                "M2313.76,569.79c-143,0-216.26-95-216.26-197.95,0-134.28,114.23-202.3,214.51-202.3,109.87,0,201.43,72.38,201.43,178.76,0,18.31-1.74,48.83-2.61,57.55H2178.6c4.36,29.65,32.26,83.71,135.16,83.71,63.65,0,124.69-22.67,177.88-49.7l36.63,66.27C2466.36,542.76,2389.62,569.79,2313.76,569.79Zm117.72-258.11c-22.68-48-75-61-123.83-61-50.57,0-105.51,23.54-123,74.12H2435Z",
                                "M2825.61,569.79c-105.51,0-210.15-72.38-210.15-200.56,0-95.05,69.76-200.56,220.62-200.56,32.26,0,63.65,5.23,93.3,16.57V168.67h79.35V339.58h-79.35V270.69c-27-13.08-60.17-21.8-93.3-21.8-87.2,0-140.39,52.32-140.39,121.21,0,80.22,68,119.46,136.9,119.46,66.27,0,111.61-27.9,159.57-62.78l45.35,61.91C2980,535.78,2905.84,569.79,2825.61,569.79Z",
                                "M3374.09,569.79c-61,0-150-17.44-150-136.91V262h-54.93V180.88h54.93V64.9h81.1v116h207.53V262H3305.21V413.7c0,37.5,4.36,75.86,69.76,75.86,56.68,0,143.87-38.36,175.27-52.32l14,80.23C3507.51,541.88,3440.36,569.79,3374.09,569.79Z",
                                "M3892.93,569.79c-44.48,0-88.95-10.47-126.44-25.29v25.29h-80.23V405.85h80.23v61a353.34,353.34,0,0,0,122.08,21.8c46.21,0,101.15-14.82,101.15-44.47,0-9.59-2.62-18.31-12.21-23.54-27-14.83-65.4-10.47-96.79-13.08-61-5.24-183.12-14-183.12-119.47,0-86.32,87.2-119.46,163.06-119.46,38.37,0,78.48,7.85,109.87,20.06V168.67h80.23V320.4h-80.23V271.57c-28.77-14-67.14-21.8-102.89-21.8-19.19,0-93.31,4.36-93.31,40.11,0,32.26,58.43,37.49,79.36,39.24l109,8.72c55.8,4.36,104.63,42.72,104.63,106.38C4067.32,521,3989.72,569.79,3892.93,569.79Z"]
                        } res1="4067.7" res2="720.15" word="Projects" />

                        <h1 className="PackPal"> {"<"}h1{">"} Package Pal {"<"}\h1{">"}</h1>

                        <p className="ind1">An AI Driven Package Indexing tool designed for colleges or small businesses</p>
                        <p className="ind1">Designed using an Angular Front End using a RESTful architecture in parallel with a Python Backend</p>
                        <p className="ind1">Deployed using Google App Engine, Cloud SQL databases, and Google's Image Text Recognition </p>
                        <p className="ind1"><i>Backend Developer and Deployment, Fall 2019 </i></p>

                        <br></br>

                        <h1 className="Datify"> {"<"}h1{">"} Datify {"<"}\h1{">"}</h1>

                        <p className="ind1">An online music streaming service with recommendation engine (copyright-free music of course)</p>
                        <p className="ind1">Built using a Flask and Jinja python server interfacing with a SQL Database </p>
                        <p className="ind1"><i>Project Manager, Spring 2019 </i></p>

                        <br></br>

                        <h2 className="seq"> {"<"}h2{">"} No Peeking {"<"}\h2{">"}</h2>

                        <p className="ind1">An Apache Module designed to obfuscate filenames of locally cached resources</p>
                        <p className="ind1">Performance improvement by factor of 5x compared to no caching </p>
                        <p className="ind1">Renames outgoing resources based off of IP address in request and salt </p>
                        <p className="ind1">Module provides drop in security to existing frameworks and prevents timing attacks  </p>
                        <p className="ind1"><i>Co-Developer, Spring 2020 </i></p>

                        <br></br>
                        <h2 className="prims"> {"<"}h2{">"} Primality Proving! {"<"}\h2{">"}</h2>

                        <p className="ind1">Primality Certificate Generation entirely in Python</p>
                        <p className="ind1">Pratt and AGKM Certificate Generation to prove primes </p>
                        <p className="ind1">Developed as part of Cryptography course <i>(MATH 408)</i> </p>
                        <p className="ind1"><i>Developer, Spring 2020 </i></p>

                        <br></br>

                        <h3 className="interpret"> {"<"}h3{">"} Interpreter in Scheme {"<"}\h3{">"}</h3>

                        <p className="ind1">Designed an Object-Oriented Language Interpreter in Scheme </p>
                        <p className="ind1">Receives semantic representation of code and generates object model and executes </p>
                        <p className="ind1">Developed as part of Programming Language Concepts <i>(EECS 345)</i> </p>
                        <p className="ind1"><i>Developer // Team Lead, Spring 2020 </i></p>

                    </div>
                </div>
                <KeyboardRend />
            </div>
        );
    }
}

export default Monitor