import React, { Component } from 'react'
import ListMaker from './ListMaker'
import './../Styling/ListMaker.css'

/**
 * Dynamically takes list of texts and generates multi-line list which populates through type in mechanic 
 * @param {texts} props An array of strings passed in through props
 * @param {emph} props What input should be displayed prominently in the list? -1 is none.
 * @param {phrase} props Set to "lists" if part of list, otherwise will create individual head based off of first string in array
 */
class Typein extends Component {
    // Class Constructor  
    constructor(props) {
        super(props);

        // What the final text will be
        const target = this.props.texts;

        // Create the empty text to start, the appropriate number
        let empt;
        if (this.props.phrase === "lists") {
            empt = ["|"];
        }
        else {
            empt = ["."];
        }
        for(let i = 1; i < target.length; i++) {
            empt.push("|");
        }

        // Tracks the Reference to this element to know when is on page 
        this.myRef = React.createRef();

        // Configure the state for the class
        this.state = {
            iteration: 0,
            text: empt,
            final: target,
            showing: true,
            blink: false
        };

        // Prepare for scroll binding 
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        // Wait until it is on screen
        window.addEventListener('scroll', this.handleScroll);
    }

    // Done on umnount of the page
    componentWillUnmount() {
        clearInterval(this.interval);
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        // height displayed by the screen
        const screenHeight = window.innerHeight;
  
        // Between the outer div and the top of the screen
        const distToTop = this.myRef.current.getBoundingClientRect().top;
  
        // check if it is on the screen
        const wiggle = screenHeight * .2
        if (distToTop + wiggle < screenHeight) {
            // The time interval per character
            let incs;
            if (this.props.phrase === "lists") {
                incs = 20;
            } else {
                incs = 100;
            }
            
            this.interval = setInterval(() => {
                let final = this.state.text;                                        // Stores the final text for this incrememntation, starts with previous 
                var internalblink = false;                                          // We by default do not blink at the start, we blink once we finish writting
                var interalshowing = this.state.showing;                            // We are showing the type box by default at the start 

                // If we are still writting 
                if (!this.state.blink) {
                    let listInd = 0;                                                // Current Line we are on 
                    let wc = this.state.iteration - this.state.final[0].length;     // current letter location we are at 

                    // Update Line Number accordingly 
                    while (wc > 0) {
                        listInd += 1;
                        wc -= this.state.final[listInd].length;
                    }
                    wc += this.state.final[listInd].length;                         // Make the number positive again 

                    // update the previous lines to remove the block 
                    if (wc === 1) {
                        for (let i = 0; i < listInd; i++) {
                            final[i] = this.state.final[i];
                        }
                    }

                    //console.log(`Iteration: ${this.state.iteration} WC: ${wc} ListInd: ${listInd} FinalLength: ${this.state.final[this.state.final.length - 1].length}`);

                    // Add the new character 
                    final[listInd] = this.state.final[listInd].substr(0, wc);
                    final[listInd] += "█";

                    // Stop executing when reach name length 
                    if (wc === this.state.final[this.state.final.length - 1].length && listInd === this.state.final.length - 1)
                        internalblink = true;
                }
                // If we are just blinking at the end 
                else {
                    internalblink = true;                                           // Restate that we are blinking

                    // Only blink after an appropriate amount of time 
                    if (this.state.iteration % (800 / incs) === 0) {
                        if (this.state.showing) {                                   // Update to not showing the block
                            final[final.length - 1] = this.state.final[final.length - 1] + "▯";
                            interalshowing = false;
                        }
                        else {                                                      // Update to showing the block 
                            final[final.length - 1] = this.state.final[final.length - 1] + "▮";
                            interalshowing = true;
                        }
                        if (this.state.iteration === (800 / incs) * 20) {           // Eventually Stop blinking 
                            final[final.length - 1] = this.state.final[final.length - 1];
                            clearInterval(this.interval);
                        }
                    }
                }
                this.setState({ text: final, iteration: this.state.iteration + 1, showing: interalshowing, blink: internalblink });
            }, incs);
            window.removeEventListener('scroll', this.handleScroll);
        }
    }

    render() {
        if (this.props.phrase === "lists") {
            return (
                <div className={"typein " + this.props.phrase} ref={this.myRef}>
                    <ListMaker texts={this.state.text} emph={this.props.emph} />
                </div>
            );
        }
        else {
            return(
                <div className="typein typeHead" ref={this.myRef}>
                    <h1><b>{this.state.text[0]}</b></h1>
                </div>
            );
        }
    }
}

export default Typein;