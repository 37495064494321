import React, { Component } from 'react'
import {Link} from "react-router-dom";
import './Styling/Splash.css';
import './Styling/Boot.css';

/**
 * Splash screen features on landing of page, generated using Bootstrap
 */
class Splash extends Component {
  // Class Constructor 
  constructor(props) {
    super(props); 

    // Creat first random text
    const target = "Hi, I'm Jacob Engelbrecht.";
    const hids = ['!', '#', '/', '\\', '?', '$', '&', '*', '%', '@', '<', '>', '+', '=', '[', ']', '{', '}', '~', '^'];
    let final = ""; 
    for(let i = 0; i <= target.length; i++) {
      final += hids[Math.floor(Math.random() * hids.length)];
    }

    // Configure the state for the class
    this.state = {
      height: 0,
      angle: 63.8,
      iteration: 0,
      text: final,
      finalText: target
    };

    // Update window size
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);    
  }

  componentDidMount() {
    this.sendMe(); 

    // Update the screen size 
    this.updateWindowDimensions();

    // Monitor for changing window size 
    window.addEventListener('resize', this.updateWindowDimensions);

    // First load name graphic 
    this.interval = setInterval(() => {
      const hids = ['!', '#', '/', '\\', '?', '$', '&', '*', '%', '@', '<', '>', '+', '=', '[', ']', '{', '}', '~', '^'];
      let final = "";
      var i;
      var name = this.state.finalText;
      // Create the proper name up until the iteration 
      for(i = 0; i <= this.state.iteration; i++) {
          final += name[i];
      }
      // Fill the rest with random characters from hids 
      while(i < name.length) {
          final += hids[Math.floor(Math.random() * hids.length)];
          i++; 
      }
      // Stop executing when reach name length 
      if (this.state.iteration + 1 === name.length) {
        clearInterval(this.interval);
      }
      this.setState({ text: final, iteration: this.state.iteration + 1 });
    }, 80); 
  }

  sendMe() {
    if (sessionStorage.getItem('sent') === null) {
      var xhr = new XMLHttpRequest()
      //xhr.addEventListener('load', () => {
      //  console.log(xhr.responseText);
      //});
      var mobile;
      if (window.innerHeight > window.innerWidth) {
        mobile = true;
      } else {
        mobile = false;
      }
      xhr.open('POST', '/cgi-bin/test.cgi');
      xhr.send(JSON.stringify({ isMobile: mobile }));
      sessionStorage.setItem('sent', true);
    }
  }

  // Done on umnount of the page
  componentWillUnmount() {
    clearInterval(this.interval);
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  // Does the calculation for the angle of the background 
  updateWindowDimensions() {
    const width = window.innerWidth;
    const height = window.innerHeight - 50;
    const wiggle = 60;
    if(height - wiggle > this.state.height || height + wiggle < this.state.height) {
      this.setState({height: height});
      if(!isNaN(Math.atan(width / height))){
        this.setState({ angle: (Math.atan(width / height) * (180) / Math.PI) });
      }
    }

    document.documentElement.style.setProperty('--set-height', `${window.innerHeight}px`);
  }

  // Renders the final graphic 
  render() {
    var name = this.state.text;
    document.documentElement.style.setProperty('--aspect-ratio', ('-' + this.state.angle + 'deg')); // Adjust the CSS angle based on the screen

    return (
      <header className="page-header header container-fluid">
          <div className="overlay"></div>
          <div className="description">
            <div className="heads">
              <h1>{name}</h1>
            </div>
            <p>I'm a senior at Case Western Reserve University studying Computer Science. 
              I focus in Computer Networks and Security, with experience designing backend RESTful APIs, 
              security implementation, and Cloud Project Networks. This website is intended to showcase my work.</p>
            <Link to="/about">
              <button className="btn btn-outline-secondary btn-lg">Tell Me More!</button>
            </Link>
          </div>
        </header>
    );
  }
}

export default Splash;