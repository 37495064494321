import React, { Component } from 'react'
import {Link} from "react-router-dom";
import './Styling/Grid.css';
import './Styling/Boot.css';
import {Modal, Button} from 'react-bootstrap'

/**
 * Generates the simplistic bootstrap Grid which will be updated later
 */
class Grid extends Component {

  constructor(props) {
    super(props);
    this.state = {
       name: "",
       email: "",
       message: "",
       show: false, 
    };

    this.handleClick = this.handleClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
 }

 handleClose(e) {
   this.setState({show: false})
 }

 handleChange(e) {
  if(e.target.id === "nameIn") {
    this.setState({name: e.target.value})
  }
  else if (e.target.id === "emailIn") {
    this.setState({email: e.target.value})
  }
  else if (e.target.id === "respIn") {
    this.setState({message: e.target.value})
  }
 }

 handleClick(e) {
    if(e.target.id === "submit") {
       this.callServer()
       this.setState({
        show: true,
        name: "",
        email: "",
        message: "",
        success: true,
     })
    }
 }

 callServer() {
  // Describe what is sent 
  let flight = {
    name: this.state.name.substring(0, 30),
    contact: this.state.email.substring(0, 30),
    message: this.state.message.substring(0, 1000),
    reason: "email"
  };

  // If there is a ticket, query the API, otherwise, redirect 
  fetch('https://01msm4d9ab.execute-api.us-east-2.amazonaws.com/default/websiteContact', {
    mode: 'cors',
    method: 'POST',
    headers: {
      "Content-type": "application/json",
      "Accept-Charset": "UTF-8;",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Request-Headers": "x-requested-with",
      "Access-Control-Allow-Headers": "x-requested-with"
    },
    body: JSON.stringify(flight)
  }).then(res => res.json())
    .then(
      (result) => {
        console.log("Successful POST")
        console.log(result)
        if (result.sent) {
          console.log("it sent!")
          this.setState({success: true})
        }
        else{
          console.log("there was an error")
          this.setState({success: false})
        }
      },

      (error) => {
        console.log(error)
        console.log("ERROR!")
        this.setState({success: false})
      }
    )
 }


  render() {
    return (
      <div className="container features grid">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <h3 className="feature-title">CWRU Crew</h3>
            <img src="images/column-1s.jpg" className="img-fluid" alt="Boat Pic" />
            <p>I am currently serving as the Men's Captain of the CWRU Crew team, assisting with regattas, organization, and the hiring of a new coach.
              Crew is something I love to do and please ask me about it!</p>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <h3 className="feature-title">Photography</h3>
            <img src="images/column-2s.jpg" className="img-fluid" alt="Crew Logo" />
            <p>One of my other passions in life is photography. I started with film photography in middle school, and grew into quite the Canon camera guy.
              I really enjoy nature photography, but sports and cities are pretty interesting too. PS, this is a IR photo! </p>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <form className="collect">
              <h3 className="feature-title">Get in Touch!</h3>
              <div className="form-group">
                <input id="nameIn" value={this.state.name} onChange={this.handleChange} type="text" className="form-control" placeholder="Name" name="" />
              </div>
              <div className="form-group">
                <input id="emailIn" value={this.state.email} onChange={this.handleChange} type="email" className="form-control" placeholder="Email Address" name="email" />
                <small id="emailHelp" className="form-text text-muted">I'll never share your email with anyone else.</small>
              </div>
              <div className="form-group">
                <textarea id="respIn" value={this.state.message} onChange={this.handleChange} className="form-control" rows="4" placeholder="Have a job or a question? Send it my way!"></textarea>
              </div>
              <input type="button" id="submit" className="btn btn-secondary btn-block" value="Send" name="" onClick={this.handleClick}/>
            </form>
            <br></br>
            <div className="nav-link">
                <Link to="/contact">Find my R&#233;sum&#233; Here!</Link>
            </div>
          </div>
        </div>
        <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{this.state.success ? 'Email Sent!' : 'Email Failed :('}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.state.success ? "I'll get back to you as soon as I can, thanks for reaching out!" : "I'm sorry, please try again. If there is a bug hopefully I'll find it or let me know on LinkedIn."}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Dismiss
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
    );
  }
}

export default Grid;