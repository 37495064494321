import React from 'react'
import './../Styling/ListMaker.css'

/**
 * Helper method which utilizes the map function of javascript to assist in creating an animation
 * @param {texts} props Needs the texts to generate the structure of the list
 * @param {emph} props What input should be displayed prominently in the list? -1 is none. 
 */
const MakeLists = props => {
  const emph = parseInt(props.emph);
  const rows = props.texts.map((row, index) => {
    const key = index + "-" + props.word;
    if (index === emph) {
      return (<li key={key}><h1>{row}</h1></li>)
    } else {
      return (<li key={key}><h2>{row}</h2></li>)
    }
  });
  return <ul>{rows}</ul>
}

/**
 * This method assists TypeIn to generate dynamically sized lists containing information 
 * @param {texts} props This takes the text in an array to be build into a list 
 * @return A single outer list containing the text in inner list elements 
 */
const ListMaker = props => {
  const { texts } = props;
  const emph = props.emph;
  return (
    <MakeLists texts={texts} emph={emph} />
  )
}

export default ListMaker