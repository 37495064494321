import React from 'react';
import Grid from './../Components/Grid'

/**
 * Not sure if it will last forever, but is the root of the Contact page of this react project
 */
class Contact extends React.Component {
   render() {
      return (
         <div style={{textAlign: 'center', paddingTop: 60, paddingBottom: 120, paddingLeft: 60, paddingRight: 60}}>
            <h1>Contact Me!</h1>
            <a href="/resources/Jacob-Engelbrecht-Resume-41.pdf" download style={{fontSize: '1.4em'}}>Click to Download R&#233;sum&#233;!</a>
            <Grid />
         </div>
      )
   }
}
export default Contact;