import React from 'react'

/**
 * Helper method which utilizes the map function of javascript to assist in creating an animation
 * @param {paths, word} props Needs the paths to generate the layer of all paths, in addition to the word for unique key making creation
 */
const MakePaths = props => {
    const rows = props.paths.map((row, index) => {
        const key = index + "-" + props.word;
        let classes = "cls-1 " + props.word;
        return ( <path key={key} className={classes} d={row}></path> )
    });
    if (props.word === "CWRU"){
      return <g id="Layer_1-2" data-name="Layer 1">{rows}<rect id="oneRect" className="cls-1 CWRU" x="77.87" y="39.24" width="248.06" height="1.32"/></g>
    }
    else {
      return <g id="Layer_1-2" data-name="Layer 1">{rows}</g>
    }
}

/**
 * This method assists Anim to generate the animation by grouping the paths 
 * @param {paths, word} props This takes the list of paths and creates unique path elements wrapped into layers 
 * @return A single layer containing all paths of the animation 
 */
const Paths = props => {
  const { paths } = props;
  const word = props.word;
  return (
    <MakePaths paths={paths} word={word}/>
  )
}

  export default Paths