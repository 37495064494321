import React, { Component } from 'react';
import Anim from './Helper/Anim'
import Typein from './Helper/Typein'
import Accord from './Helper/Accord'
import './Styling/Temps.css'

/**
 * Renders the Education component featured on the home screen. Uses animation for header entrance and Typein for text arrival 
 */
class Education extends Component {
  render() {
    return (
      <div className="edu">
        <Anim paths={
          ["M363.84,378.74H109.22V569.49H406.84v86H1.5V35.56H403.86v86.86H109.22V293.59H363.84Z",
            "M465.18,421.75q0-106.44,49.39-171T647,186.29q73.23,0,118.37,51.09V1.5H868.82v654H775.14L770,607.81Q723.62,664,646.13,664q-80.89,0-130.92-65.15T465.18,421.75Zm103.46,8.94q0,70.24,27,109.64t76.85,39.38q63.44,0,92.82-56.63V326.37Q736.82,271,673.38,271q-50.24,0-77.49,39.81T568.64,430.69Z",
            "M1255.85,610.37Q1210.28,664,1126.41,664q-74.94,0-113.47-43.86T974.41,493.28V194.8h103.47V492q0,87.72,72.8,87.71,75.38,0,101.77-54.07V194.8h103.46V655.5h-97.5Z",
            "M1650.55,581.41q38.75,0,64.29-22.56t27.25-55.78h97.51q-1.71,43-26.83,80.26t-68.12,59q-43,21.72-92.82,21.72-96.66,0-153.28-62.59t-56.63-172.87V417.91q0-105.15,56.2-168.39t153.28-63.23q82.17,0,133.91,47.9T1839.6,360h-97.51q-1.69-39.6-27-65.15t-64.51-25.54q-50.25,0-77.49,36.4t-27.68,110.49v16.61q0,74.94,27,111.76T1650.55,581.41Z",
            "M2193,655.5q-6.83-13.18-11.93-43Q2131.67,664,2060.15,664q-69.4,0-113.26-39.6T1903,526.49q0-73.67,54.71-113t156.48-39.38h63.44V343.83q0-35.77-20-57.27t-60.89-21.5q-35.34,0-57.91,17.67t-22.56,44.92H1912.83q0-37.89,25.12-70.89T2006.29,205q43.22-18.72,96.44-18.73,80.89,0,129,40.66t49.39,114.32V549.05q0,62.18,17.46,99.21v7.24ZM2079.31,581a117.67,117.67,0,0,0,57.7-14.9q27-14.91,40.66-40V439.2h-55.78q-57.48,0-86.43,20t-29,56.63q0,29.82,19.8,47.48T2079.31,581Z",
            "M2519.14,82.82v112h81.33v76.65h-81.33V528.62q0,26.4,10.44,38.1t37.25,11.71a159.25,159.25,0,0,0,36.19-4.25v80q-35.34,9.79-68.12,9.8-119.22,0-119.22-131.57v-261h-75.79V194.8h75.79v-112Z",
            "M7989.32,6941.9q0-23.83,15.12-39.6t43.22-15.75q28.1,0,43.43,15.75t15.32,39.6q0,23.43-15.32,39t-43.43,15.54q-28.11,0-43.22-15.54T7989.32,6941.9Zm109.86,580.34H7995.71V7061.55h103.47Z",
            "M2679.66,75.16q0-23.83,15.12-39.6T2738,19.81q28.1,0,43.43,15.75t15.32,39.6q0,23.41-15.32,39T2738,129.66q-28.11,0-43.22-15.54T2679.66,75.16ZM2789.52,655.5H2686.05V194.8h103.47Z",
            "M2882.76,420.89q0-67.69,26.83-122T2985,215.46q48.54-29.16,111.55-29.17,93.26,0,151.37,60t62.8,159.25l.43,24.27q0,68.12-26.19,121.77t-74.94,83Q3161.22,664,3097.36,664q-97.52,0-156.05-64.94T2882.76,426Zm103.47,8.95q0,71.1,29.38,111.34t81.75,40.23q52.36,0,81.53-40.87t29.17-119.65q0-69.83-30-110.7t-81.54-40.87q-50.67,0-80.47,40.23T2986.23,429.84Z",
            "M3494.61,194.8l3,53.23q51.09-61.74,134.12-61.74,143.91,0,146.47,164.78V655.5H3674.71V357q0-43.86-18.94-64.93T3593.82,271q-62.59,0-93.25,56.63V655.5H3397.11V194.8Z"]
        } res1="3779.68" res2="665.52" word="Education" />
          <Anim paths={
          ["M87.55,3.17a5.06,5.06,0,0,0-3.62-1.58,4.92,4.92,0,0,0,0,9.83,5.2,5.2,0,0,0,3.59-1.57v1.63A6,6,0,0,1,84,12.65a6.18,6.18,0,0,1-6.2-6.1A6.22,6.22,0,0,1,84,.36a5.71,5.71,0,0,1,3.54,1.17Z",
          "M92.43,9.07,91,12.45H89.54L95,0l5.34,12.45H98.9L97.5,9.07Zm2.57-6L93,7.85h4Z",
          "M107.77,2.88a2.18,2.18,0,0,0-2-1.29,1.89,1.89,0,0,0-2,1.84c0,1,1.06,1.5,1.86,1.86l.79.35c1.54.68,2.85,1.45,2.85,3.34a3.75,3.75,0,0,1-7.44.6l1.3-.36a2.44,2.44,0,0,0,2.38,2.2,2.38,2.38,0,0,0,2.44-2.31c0-1.39-1.09-1.86-2.19-2.37L105,6.43c-1.38-.63-2.58-1.34-2.58-3a3.12,3.12,0,0,1,3.32-3,3.39,3.39,0,0,1,3.07,1.85Z",
          "M112,.57h6.16V1.8h-4.83V5.32h4.69V6.55h-4.69v4.67h4.83v1.23H112Z",
          "M132.26,2.88a2.18,2.18,0,0,0-2-1.29,1.88,1.88,0,0,0-2,1.84c0,1,1,1.5,1.86,1.86l.79.35c1.54.68,2.85,1.45,2.85,3.34a3.75,3.75,0,0,1-7.44.6l1.29-.36a2.46,2.46,0,0,0,2.38,2.2,2.39,2.39,0,0,0,2.45-2.31c0-1.39-1.09-1.86-2.2-2.37l-.72-.31c-1.39-.63-2.58-1.34-2.58-3a3.12,3.12,0,0,1,3.32-3,3.41,3.41,0,0,1,3.07,1.85Z",
          "M145.56,3.17a5.1,5.1,0,0,0-3.63-1.58,4.92,4.92,0,0,0,0,9.83,5.24,5.24,0,0,0,3.6-1.57v1.63A6.08,6.08,0,0,1,142,12.65a6.18,6.18,0,0,1-6.19-6.1A6.21,6.21,0,0,1,142,.36a5.74,5.74,0,0,1,3.55,1.17Z", 
          "M156,5.39V.57h1.32V12.45H156V6.62H150v5.83h-1.33V.57H150V5.39Z", 
          "M172.58,6.52a6.19,6.19,0,0,1-12.38,0,6.19,6.19,0,0,1,12.38,0Zm-11.05,0a4.87,4.87,0,1,0,4.86-4.92A4.91,4.91,0,0,0,161.53,6.51Z", 
          "M187.15,6.52a6.19,6.19,0,0,1-12.38,0,6.19,6.19,0,0,1,12.38,0Zm-11.06,0A4.87,4.87,0,1,0,181,1.59,4.91,4.91,0,0,0,176.09,6.51Z", 
          "M191.29,11.22h3.28v1.23H190V.57h1.32Z", 
          "M90.28,25.77a6.2,6.2,0,1,1-6.19-6.16A6.16,6.16,0,0,1,90.28,25.77Zm-11.06,0a4.87,4.87,0,1,0,4.87-4.92A4.91,4.91,0,0,0,79.22,25.76Z", 
          "M94.42,21.05v3.52h4V25.8h-4v5.9H93.09V19.82H98.5v1.23Z",
          "M107.05,19.82h6.16v1.23h-4.84v3.52h4.7V25.8h-4.7v4.67h4.84V31.7h-6.16Z",
          "M116.16,19.31,125.43,29v-9.2h1.32V32.23l-9.26-9.69V31.7h-1.33Z",
          "M141.3,25.72a5.66,5.66,0,0,1-1.23,4.2,5.87,5.87,0,0,1-4.4,2,6.15,6.15,0,1,1,5.11-9.72l-1,.9a4.86,4.86,0,0,0-4.08-2.24,4.94,4.94,0,0,0-4.86,5,4.81,4.81,0,0,0,1.39,3.36,5,5,0,0,0,3.37,1.51c2.07,0,4.24-1.51,4.24-3.72h-3.53V25.72Z", 
          "M145.47,31.7h-1.32V19.82h1.32Z",
          "M148.93,19.31,158.19,29v-9.2h1.32V32.23l-9.26-9.69V31.7h-1.32Z", 
          "M163,19.82h6.16v1.23h-4.84v3.52H169V25.8h-4.69v4.67h4.84V31.7H163Z",
          "M172.06,19.82h6.16v1.23h-4.84v3.52h4.7V25.8h-4.7v4.67h4.84V31.7h-6.16Z",
          "M182.46,31.7h-1.33V19.82h1.72c2.26,0,4.35.59,4.35,3.27a3.11,3.11,0,0,1-3,3.28L188,31.7h-1.62l-3.59-5.22h-.33Zm0-6.38h.41c1.5,0,3-.29,3-2.13s-1.44-2.14-3-2.14h-.39Z",
          "M191.79,31.7h-1.33V19.82h1.33Z",
          "M195.24,19.31,204.5,29v-9.2h1.33V32.23l-9.27-9.69V31.7h-1.32Z",
          "M220.37,25.72a5.65,5.65,0,0,1-1.22,4.2,5.87,5.87,0,0,1-4.4,2,6.15,6.15,0,1,1,5.1-9.72l-1,.9a4.83,4.83,0,0,0-4.08-2.24,4.93,4.93,0,0,0-4.85,5,4.77,4.77,0,0,0,1.39,3.36,5,5,0,0,0,3.37,1.51c2.06,0,4.24-1.51,4.24-3.72h-3.53V25.72Z",
          "M88.74,67.22c-6.67,0-10.83-4.48-10.83-9.47,0-4.8,4.54-10.24,11-10.24a13.6,13.6,0,0,1,6.19,1.56,14.37,14.37,0,0,1,.55,4c0,.14-.46.11-.49,0-.5-2.26-2.64-4.77-6.94-4.77s-7.6,3.07-7.6,8.29c0,6.39,4.3,9.62,8.44,9.62,3,0,6-1.79,6.21-4.74,0-.08.55-.11.55.07a14,14,0,0,1-.6,4C94.12,66.44,92.3,67.22,88.74,67.22Z",
          "M104.93,66.76c-.17,0-.09-.55,0-.55,1-.12,1.59,0,1.59-.55a10.9,10.9,0,0,0-.75-2.72l-.53-1.53h-4.68l-.66,1.53A8.29,8.29,0,0,0,99,65.63c0,.49.55.49,1.48.58.14,0,.14.6,0,.55A20.23,20.23,0,0,0,98,66.64a14.12,14.12,0,0,0-2.39.12c-.18,0-.09-.55,0-.55,1.44-.12,2.19-.9,3.29-3.33l4.8-10.49c0-.14.34-.17.4,0l4.16,10.49c1,2.33.88,3.17,2.62,3.33.08,0,.14.6,0,.55a15.31,15.31,0,0,0-2.19-.12A35.43,35.43,0,0,0,104.93,66.76Zm-4-6.22h4l-1.79-4.85Z",
          "M111.45,65.83a18.53,18.53,0,0,1-.22-3.24c0-.14.52-.13.52-.07.36,2.18,1.65,3.81,3.9,3.62a2.36,2.36,0,0,0,2.43-2.52c-.29-3.6-6.63-3.07-7-7a4.29,4.29,0,0,1,4.1-4.4,5.19,5.19,0,0,1,2.89.58,6.38,6.38,0,0,1,.64,2.73c0,.08-.58.13-.59,0a2.87,2.87,0,0,0-3.07-2.43c-1.35.1-2.3.85-2.19,2.17.28,3.46,6.49,2.86,6.84,7a4.42,4.42,0,0,1-4.21,4.78A6,6,0,0,1,111.45,65.83Z",
          "M130.23,66.76c-.81,0-2.17-.12-3.79-.12-2.2,0-4.82,0-6.44.12-.17,0-.09-.55,0-.55,2.19-.26,2.4-.55,2.4-3.3V56.39c0-2.75-.21-3.1-2.4-3.33-.09,0-.17-.61,0-.58,1.62.15,4.16.18,6.35.18,1.56,0,3.07-.06,4.4-.18a5.24,5.24,0,0,0-.15,1.5c0,.23,0,.87,0,1s-.55.1-.55,0c-.17-1-.52-1.41-3.32-1.41h-1.88a17.44,17.44,0,0,0-.11,2.65v2.66h1.44c2.43,0,2.6-.06,2.83-1,0-.06.55-.09.52.06a10.53,10.53,0,0,0-.11,1.7V61a1.66,1.66,0,0,1-.56,0c-.19-1.13-.37-1.21-2.68-1.21h-1.44v2.85c0,2.78.13,3,1.12,3H127c3.32,0,3.41-.46,3.72-1.87,0,0,.58,0,.58.05-.09.55-.32,2-.46,2.4S130.6,66.76,130.23,66.76Z",
          "M236.81,52.51a20.26,20.26,0,0,1-2.54.14,17,17,0,0,1-2.86-.14c-.18-.06-.18.52,0,.55,1.88.2,2.17.84,2.17,3.32v6.73L223.46,52.68a31.07,31.07,0,0,1-3.81-.17c-.18-.06-.12.55,0,.55,1,.11,2.8,1.44,2.8,2.42v7.43c0,2.36-.37,3.06-1.9,3.2a.59.59,0,0,1-.39-.08c-3.3-2.71-3.25-4.44-4-6.42A4.09,4.09,0,0,0,218.84,56c0-2.32-2.08-3.39-6-3.56a32.66,32.66,0,0,0-5.87.05c-.17,0,0,.57.1.51a4.45,4.45,0,0,1,.81,0c1,0,1.46.83,1.46,3.35v6.53c0,2.75-.34,3.09-2.22,3.29-.09,0-.17.61,0,.55a35.89,35.89,0,0,1,3.61-.11c.93,0,2.4,0,3.06.11.18.06.12-.55,0-.55-2-.17-2.17-.69-2.17-3.29V60.48a6.22,6.22,0,0,0,.83,0,12.73,12.73,0,0,0,1.63-.17,11,11,0,0,0,5.48,6.4l.2,0h.39s1.86-.11,3.13-.11a18.16,18.16,0,0,1,2.35.11c.17.06.11-.55.06-.55-2-.17-2.21-1-2.21-3.29V56l10.79,11c.17.17.52,0,.52,0-.11-.93-.14-2.89-.14-4V56.38c0-2.31.17-3.18,2.19-3.32C237,53.06,237.1,52.48,236.81,52.51Zm-24.33,7.16c-.38,0-.55,0-.81,0V56.18a12.46,12.46,0,0,1,.18-2.81,5.65,5.65,0,0,1,.71,0c2.52,0,3.9.86,3.9,3.29C216.46,58.57,215.34,59.67,212.48,59.67Z",
          "M173.16,65.94c-.12-.09-.09-.64,0-2.17,0-.15.5-.09.52-.05.93,1.91,1.49,2.52,3.63,2.42a2.48,2.48,0,0,0,2.51-2.37c-.09-3.62-6.44-3.46-6.54-7.41a4.28,4.28,0,0,1,4.34-4.16,5.22,5.22,0,0,1,2.86.74,6.46,6.46,0,0,1,.47,2.76c0,.09-.57.1-.58,0a2.89,2.89,0,0,0-2.93-2.62c-1.35,0-2.35.72-2.31,2,.08,3.47,6.32,3.23,6.42,7.39a4.37,4.37,0,0,1-4.42,4.53A5.5,5.5,0,0,1,173.16,65.94Z",
          "M153.54,67a.28.28,0,0,1-.49,0l-4.36-12.25L143.29,67c0,.15-.44.2-.52,0l-5.58-13.92c-1.39-3.47-1.85-4.46-4.13-4.66-.14,0-.26-.52,0-.49a23.13,23.13,0,0,0,3.09.17,42.72,42.72,0,0,0,4.62-.17c.29,0,.12.49,0,.49-1.33.12-2.05.3-2.05,1.07a17,17,0,0,0,1,3.53L144,63.29l4.28-9.89-.14-.34c-1.42-3.39-1.74-4.46-3.93-4.66-.09,0-.21-.52,0-.49a23.33,23.33,0,0,0,3.09.17,48,48,0,0,0,4.88-.17c.23,0,.14.49.06.49-1.8.15-2.37.35-2.37,1.22a13,13,0,0,0,.87,3.38l3.49,10.29,4.13-10.44a10.88,10.88,0,0,0,1-3.35c0-.72-.45-1-1.87-1.1-.09,0-.26-.52,0-.49a19.85,19.85,0,0,0,2.87.17,13.7,13.7,0,0,0,2.71-.17c.23,0,.12.49,0,.49-1.82.18-2.4,1.42-3.76,4.66Z",
          "M170.88,66.75c-.81,0-2.16-.11-3.78-.11-2.2,0-4.83,0-6.45.11-.17,0-.08-.55,0-.55,2.2-.26,2.4-.54,2.4-3.29V56.38c0-2.75-.2-3.09-2.4-3.32-.08,0-.17-.61,0-.58,1.62.14,4.16.17,6.36.17,1.56,0,3.06,0,4.39-.17a5.52,5.52,0,0,0-.14,1.5c0,.23,0,.87,0,1s-.55.09-.55,0c-.18-1-.52-1.41-3.32-1.41h-1.88a17.17,17.17,0,0,0-.12,2.65v2.66h1.45c2.42,0,2.6-.06,2.83-1,0-.06.55-.09.52.06a10.43,10.43,0,0,0-.12,1.7v1.24a1.6,1.6,0,0,1-.55,0c-.2-1.12-.37-1.21-2.68-1.21h-1.45v2.86c0,2.78.15,3,1.13,3h1.13c3.32,0,3.41-.47,3.72-1.88,0,0,.59,0,.59,0a24.32,24.32,0,0,1-.47,2.4C171.4,66.66,171.26,66.75,170.88,66.75Z",
          "M194.44,55.37c0,.08-.52.06-.52,0-.2-.95-.6-1.62-2.91-1.62h-1.56v9.16c0,2.57.18,3.12,2.2,3.29.09,0,.14.58-.08.55-.67-.09-2.15-.11-3.07-.11a34.75,34.75,0,0,0-3.55.11c-.23.06-.12-.55,0-.55,1.91-.2,2.19-.58,2.19-3.29V53.75h-1.66c-2.23,0-2.74.63-3,1.65-.12,0-.55,0-.52-.06a14.31,14.31,0,0,0,.15-2.17,5.39,5.39,0,0,0-.12-.63c0-.09.45-.08.52,0,.44.37,1.17.22,3,.23H191c1.75,0,2.82.14,3.05-.23.05-.08.58,0,.52.06a5,5,0,0,0-.17,1.44C194.41,54.36,194.41,55.08,194.44,55.37Z",
          "M205.36,66.75c-.81,0-2.16-.11-3.79-.11-2.19,0-4.82,0-6.44.11-.17,0-.08-.55,0-.55,2.2-.26,2.4-.54,2.4-3.29V56.38c0-2.75-.2-3.09-2.4-3.32-.08,0-.17-.61,0-.58,1.62.14,4.16.17,6.36.17,1.56,0,3.06,0,4.39-.17a5.17,5.17,0,0,0-.15,1.5c0,.23,0,.87,0,1s-.54.09-.54,0c-.18-1-.52-1.41-3.32-1.41H200a17.17,17.17,0,0,0-.12,2.65v2.66h1.44c2.44,0,2.61-.06,2.84-1,0-.06.55-.09.52.06a9.41,9.41,0,0,0-.12,1.7v1.24a1.6,1.6,0,0,1-.55,0c-.2-1.12-.37-1.21-2.69-1.21h-1.44v2.86c0,2.78.15,3,1.13,3h1.12c3.33,0,3.42-.47,3.73-1.88,0,0,.59,0,.59,0-.1.55-.32,2-.47,2.4S205.73,66.75,205.36,66.75Z",
          "M269,66c-.11-.09-.09-.64,0-2.17,0-.15.49-.1.52-.05.92,1.9,1.48,2.52,3.62,2.42a2.51,2.51,0,0,0,2.52-2.37c-.1-3.62-6.45-3.46-6.55-7.41a4.3,4.3,0,0,1,4.34-4.17,5.28,5.28,0,0,1,2.86.74,6.45,6.45,0,0,1,.48,2.77c0,.08-.58.09-.59,0a2.89,2.89,0,0,0-2.92-2.62c-1.36,0-2.36.73-2.32,2.06.09,3.47,6.32,3.22,6.42,7.38a4.37,4.37,0,0,1-4.42,4.54A5.5,5.5,0,0,1,269,66Z",
          "M248.45,58.2a8.29,8.29,0,0,1-1.8.15,10.19,10.19,0,0,1-1.1-.06V61.7c0,4.14,0,4.34,2.66,4.57.15,0,.2.6,0,.54a34.74,34.74,0,0,0-3.59-.17,31.64,31.64,0,0,0-4.25.17c-.29,0-.17-.52,0-.54C242.87,66,243,66,243,61.7V53c0-3.26-.09-4.27-1.73-4.27a6.09,6.09,0,0,0-1.11.11c-.08,0-.31-.43,0-.52a33.43,33.43,0,0,1,7.22-.49c4.22,0,7.69,1.27,7.69,4.51,0,1.62-1.3,4.07-4.3,5.29,2.8,3.93,3,12.41,10,12.87a5,5,0,0,0,2.23-.29c.17,0,.42.41.2.49a7.88,7.88,0,0,1-4,.61C251.51,70.68,250.84,61.85,248.45,58.2Zm-2.69-9.5a21.81,21.81,0,0,0-.21,4v4.8a9.52,9.52,0,0,0,1.07,0c4.37,0,5.87-1.59,5.87-4,0-3.92-2.58-5-5.46-5A9.6,9.6,0,0,0,245.76,48.7Z",
          "M266.55,66.76c-.81,0-2.17-.12-3.78-.12-2.2,0-4.47,0-6.08.12-.19,0-.1-.55,0-.55,2.18-.26,2-.55,2-3.3V56.39c0-2.75-.2-3.1-2.39-3.33-.1,0-.18-.6,0-.58,1.61.15,4.16.18,6.35.18,1.56,0,3.06-.06,4.39-.18a5.63,5.63,0,0,0-.14,1.51c0,.22,0,.86,0,1s-.55.09-.55,0c-.17-1-.52-1.41-3.32-1.41h-1.88a17,17,0,0,0-.12,2.65v2.66h1.45c2.43,0,2.6-.06,2.83-1,0-.06.55-.09.52.06a9.41,9.41,0,0,0-.12,1.7V61a1.54,1.54,0,0,1-.54,0c-.2-1.12-.38-1.21-2.69-1.21h-1.45v2.86c0,2.77.15,3,1.13,3h1.13c3.32,0,3.41-.46,3.73-1.87,0,0,.57,0,.57.05-.09.55-.32,2-.46,2.4S266.93,66.76,266.55,66.76Z",
          "M288.12,66.76c-.8,0-2.16-.12-3.78-.12-2.2,0-4.83,0-6.44.12-.18,0-.09-.55,0-.55,2.2-.26,2.39-.55,2.39-3.3V56.39c0-2.75-.19-3.1-2.39-3.33-.09,0-.18-.6,0-.58,1.61.15,4.16.18,6.36.18,1.55,0,3.06-.06,4.39-.18A5.27,5.27,0,0,0,288.5,54c0,.22,0,.86,0,1s-.55.09-.55,0c-.17-1-.51-1.41-3.32-1.41h-1.88a17.44,17.44,0,0,0-.11,2.65v2.66h1.44c2.43,0,2.6-.06,2.83-1,0-.06.55-.09.53.06a9.41,9.41,0,0,0-.12,1.7V61a1.6,1.6,0,0,1-.55,0c-.2-1.12-.37-1.21-2.69-1.21h-1.44v2.86c0,2.77.14,3,1.12,3h1.13c3.32,0,3.41-.46,3.72-1.87,0,0,.59,0,.59.05-.09.55-.33,2-.47,2.4S288.5,66.76,288.12,66.76Z",
          "M294.94,60.52a6.31,6.31,0,0,1-.84,0v2.42c0,2.61.2,3.13,2.17,3.3.08,0,.14.6,0,.55-.66-.09-2.14-.12-3.07-.12a33,33,0,0,0-3.61.12c-.17,0-.08-.55,0-.55,1.88-.2,2.23-.55,2.23-3.3V56.39c0-2.52-.46-3.38-1.47-3.38a2.33,2.33,0,0,0-.54.06c-.12.06-.26-.47-.09-.52,1-.27,3.38,0,5.59-.07,3.9,0,6,1.25,6,3.56a4.12,4.12,0,0,1-2.75,3.58c1.16,3,4.25,6.94,6.77,6.94.09,0,.17.55,0,.55a9,9,0,0,1-8.73-6.77A11.28,11.28,0,0,1,294.94,60.52Zm-.67-7.14a12.6,12.6,0,0,0-.17,2.8v3.47c.26,0,.43,0,.81,0,2.86,0,4-1.1,4-3.07,0-2.42-1.39-3.29-3.9-3.29A6,6,0,0,0,294.27,53.38Z",
          "M308.82,66.91c0,.08-.37.14-.46,0l-4.57-10.46c-1-2.37-1.7-3.2-3.44-3.35-.08,0-.17-.6,0-.55a36,36,0,0,0,6.62,0c.18,0,.18.52,0,.55-1,.12-1.5.17-1.5.75a6.69,6.69,0,0,0,.72,2.55l3.15,7.13,3.12-7.13a6.05,6.05,0,0,0,.78-2.55c0-.49-.46-.66-1.41-.75-.1,0-.18-.6,0-.55a19.28,19.28,0,0,0,2.48.15c1.3,0,1.37,0,2.37,0,.18,0,.15.42.06.42-1.36.15-2.14.81-3.27,3.35Z",
          "M325.12,66.76c-.81,0-2.17-.12-3.79-.12-2.2,0-4.83,0-6.44.12-.18,0-.09-.55,0-.55,2.19-.26,2.39-.55,2.39-3.3V56.39c0-2.75-.2-3.1-2.39-3.33-.09,0-.16-.42,0-.42,1.58,0,4.15,0,6.34,0,1.56,0,3.07-.06,4.4-.18a5.27,5.27,0,0,0-.15,1.51c0,.22,0,.86,0,1s-.54.09-.54,0c-.18-1-.53-1.41-3.33-1.41h-1.88a17.44,17.44,0,0,0-.11,2.65v2.66h1.44c2.43,0,2.6-.06,2.83-1,0-.06.55-.09.52.06a10.53,10.53,0,0,0-.11,1.7V61a1.62,1.62,0,0,1-.55,0c-.2-1.12-.38-1.21-2.69-1.21h-1.44v2.86c0,2.77.14,3,1.12,3h1.13c3.33,0,3.41-.46,3.73-1.87,0,0,.58,0,.58.05a24.32,24.32,0,0,1-.47,2.4C325.64,66.67,325.49,66.76,325.12,66.76Z",
          "M89.05,78.67c0,2.24-1.7,4.14-4.65,4.14-2.56,0-4.74-1.61-4.74-4.26V74.68c0-2.1-.24-2.38-1.67-2.56-.07,0-.12-.44,0-.39a22.66,22.66,0,0,0,2.73.08c.71,0,1.81,0,2.34-.08.13,0,.13.39,0,.39-1.5.14-1.63.58-1.63,2.56v3.51c0,2.57,1.67,3.72,3.37,3.72,2.22,0,3.39-1.2,3.39-3.46V74.53c0-1.9-.24-2.23-1.67-2.41-.11,0-.11-.44,0-.39a14,14,0,0,0,2.16.08,18.18,18.18,0,0,0,1.92-.08c.18,0,.09.39,0,.39-1.52.14-1.65.62-1.65,2.41v4.14",
          "M109.19,79.64c0,.81,0,2.31.1,3,0,.06-.26.15-.39,0l-8.23-8.35v5.31c0,1.76.16,2.38,1.68,2.51,0,0,.09.46,0,.41a15.56,15.56,0,0,0-1.79-.08,21.19,21.19,0,0,0-2.38.08c-.11,0-.06-.41,0-.41,1.45-.15,1.68-.64,1.68-2.51V74c0-.74-1.39-1.76-2.14-1.85-.07,0-.11-.46,0-.41a25.65,25.65,0,0,0,2.92.12l7.68,8V74.65c0-1.89-.22-2.37-1.65-2.53-.11,0-.11-.46,0-.41a13.78,13.78,0,0,0,2.18.1,17.41,17.41,0,0,0,1.94-.1c.23,0,.11.41.07.41-1.54.11-1.67.77-1.67,2.53v5",
          "M121.87,82.48c-1,0-2,0-2.75.08-.18,0-.07-.41,0-.41,1.46-.15,1.68-.44,1.68-2.51v-5c0-2.09-.22-2.37-1.68-2.53,0,0-.13-.44,0-.41a22.3,22.3,0,0,0,2.73.1c.66,0,1.81,0,2.32-.1.18,0,.08.41,0,.41-1.52.11-1.67.58-1.67,2.53v5c0,2,.15,2.38,1.67,2.51,0,0,.14.44,0,.41-.51-.06-1.66-.08-2.32-.08",
          "M138.15,82.68c0,.07-.3.11-.36,0l-3.48-8c-.8-1.81-1.3-2.45-2.62-2.56-.07,0-.14-.46,0-.41.53.06,1.52.1,2.2.1a24.15,24.15,0,0,0,2.84-.1c.14,0,.14.39,0,.41-.72.09-1.14.14-1.14.58a5.15,5.15,0,0,0,.55,1.93l2.4,5.45,2.38-5.45a4.69,4.69,0,0,0,.6-1.93c0-.38-.36-.51-1.08-.58-.07,0-.13-.46,0-.41a17,17,0,0,0,1.89.1,9.46,9.46,0,0,0,1.83-.1c.13,0,.09.41,0,.41-1,.11-1.63.62-2.5,2.56l-3.56,8",
          "M158.8,82.56c-.62,0-1.65-.08-2.89-.08-1.67,0-3.68,0-4.91.08-.14,0-.07-.41,0-.41,1.67-.2,1.83-.42,1.83-2.51v-5c0-2.09-.16-2.35-1.83-2.53-.07,0-.14-.46,0-.44,1.23.11,3.17.13,4.85.13,1.19,0,2.33,0,3.34-.13a4.52,4.52,0,0,0-.1,1.14c0,.18,0,.67,0,.78a.75.75,0,0,1-.42,0c-.14-.73-.4-1.08-2.54-1.08H154.7a13.17,13.17,0,0,0-.09,2v2h1.1c1.86,0,2,0,2.16-.75,0,0,.42-.07.4,0a7.09,7.09,0,0,0-.09,1.3v1a1.07,1.07,0,0,1-.41,0c-.16-.86-.29-.92-2.06-.92h-1.1v2.18c0,2.11.11,2.31.86,2.31h.86c2.53,0,2.6-.35,2.84-1.43,0,0,.45,0,.45,0-.07.42-.24,1.5-.35,1.83s-.18.39-.47.39",
          "M172.46,77.81a5.65,5.65,0,0,1-.64,0v1.85c0,2,.16,2.38,1.66,2.51.06,0,.1.46,0,.41-.51-.06-1.64-.08-2.34-.08-1,0-2.05,0-2.75.08-.14,0-.07-.41,0-.41,1.42-.15,1.69-.42,1.69-2.51v-5c0-1.91-.35-2.33-1.12-2.33a3,3,0,0,0-.62.07c-.09,0-.2-.38-.06-.4a21.89,21.89,0,0,1,4.47-.31c3,0,4.56.95,4.56,2.71a3.13,3.13,0,0,1-2.09,2.73c.88,2.26,3,5.3,4.89,5.3.06,0,.13.41,0,.41a6.55,6.55,0,0,1-6.39-5.15,9.7,9.7,0,0,1-1.24.13m-.5-5.44a9.3,9.3,0,0,0-.14,2.13v2.65l.62,0c2.18,0,3-.84,3-2.33,0-1.86-1-2.52-3-2.52A3.41,3.41,0,0,0,172,72.37Z",
          "M187.84,81.8a12.62,12.62,0,0,1-.12-2.47c0-.11.4-.09.4,0,.24,1.67,1.2,2.93,2.91,2.82a1.79,1.79,0,0,0,1.89-1.89c-.17-2.75-5-2.43-5.21-5.45a3.29,3.29,0,0,1,3.19-3.3,4.09,4.09,0,0,1,2.19.48,4.57,4.57,0,0,1,.45,2.09c0,.07-.43.1-.44,0a2.2,2.2,0,0,0-2.31-1.9c-1,.06-1.77.62-1.7,1.63.17,2.64,4.91,2.27,5.12,5.43a3.37,3.37,0,0,1-3.28,3.59,4.49,4.49,0,0,1-3.09-1",
          "M206,82.48c-1,0-2.05,0-2.76.08-.17,0-.06-.41,0-.41,1.45-.15,1.67-.44,1.67-2.51v-5c0-2.09-.22-2.37-1.67-2.53,0,0-.14-.44,0-.41a22.22,22.22,0,0,0,2.73.1c.67,0,1.82,0,2.32-.1.18,0,.08.41,0,.41-1.52.11-1.68.58-1.68,2.53v5c0,2,.16,2.38,1.68,2.51,0,0,.14.44,0,.41-.5-.06-1.65-.08-2.32-.08",
          "M227,73.88c0,.07-.4.05-.4,0-.15-.72-.73-1.23-2.48-1.23h-1.07v7c0,2,.13,2.38,1.68,2.51.06,0,.11.44-.07.41-.5-.06-1.63-.08-2.34-.08-1,0-2,0-2.71.08-.17,0-.09-.41,0-.41,1.46-.15,1.67-.44,1.67-2.51v-7h-1.15c-1.69,0-2.35.49-2.57,1.26-.09,0-.42,0-.4,0a12.62,12.62,0,0,0,.08-1.68,2.15,2.15,0,0,0-.06-.29c0-.07.36-.07.4,0s.17,0,2.55,0h4c2.35,0,2.46.2,2.59,0s.45,0,.4.05a2.64,2.64,0,0,0-.13.92c0,.27,0,.81,0,1",
          "M246.34,72.21c-2.69,0-4.65,3.31-4.65,7v.42c0,2,.13,2.38,1.65,2.51.07,0,.12.46,0,.41-.53-.06-1.65-.08-2.33-.08-1,0-2.05,0-2.76.08-.18,0-.07-.41,0-.41,1.45-.15,1.7-.44,1.7-2.51v-.42c0-4-2.21-7-4.79-7a3,3,0,0,0-.61.07s-.16-.37,0-.4a5.42,5.42,0,0,1,1.68-.32,5.54,5.54,0,0,1,5.14,4.35c1-2.75,2.75-4.25,4.73-4.25a5.69,5.69,0,0,1,1.47.22c.13,0,0,.44-.09.4a5.33,5.33,0,0,0-1.07-.09",
          "M64.26,32.46c-.6,0-.61.73-.61.73L62.4,79.78l-28.82-.26a98.9,98.9,0,0,0-3.7-9.24l24.9,6.36h.13a.59.59,0,0,0,.58-.6.59.59,0,0,0-.37-.55h0L30.58,66.63,57,65.38h0a.59.59,0,0,0,0-1.18h0L29.56,63,56.77,52.72h0a.6.6,0,0,0-.23-1.14h-.13l-28.69,8.1,29.4-24.59a.58.58,0,0,0,.23-.46.6.6,0,0,0-.58-.6.68.68,0,0,0-.41.18l-30.5,22.2L54.11,14.47a.62.62,0,0,0,.1-.34.58.58,0,0,0-.58-.59.57.57,0,0,0-.5.3L22.74,54l11-37.81a.57.57,0,0,0,0-.18.59.59,0,0,0-.58-.6.58.58,0,0,0-.55.42L19.31,52.34,19,18.89a.58.58,0,1,0-1.16,0v0c-.07,1.14-1.7,24.93-2.25,32.85L7.59,27.86c-.11-.38-.33-.69-.77-.59s-.38.86-.38.86l5.47,24.42a43.46,43.46,0,0,0-8.7-3.13l-.76-46L35.34,1.9s.79,0,.79-.6-.81-.6-.81-.6H0L.26,82.55l64.57-.27V33.21S64.85,32.48,64.26,32.46ZM3.7,79.26,3.25,52.09c15.86,5.84,24,17.91,28,27.41Z"]
        } res1="326.19" res2="82.83" word="CWRU" />
         <br></br>
        <Typein texts={[
          "Bachelor of Science in Computer Science, focused in Computer Networks and Security",
          "Sophomore & Junior Engineering Student Excellence Award & Dean’s High Honors List Recipient",
          "Taken Courses pertaining to Artificial Intelligence, Computer Networks, Cryptography, Software Engeineering, Algorithms",
          "Cumulative GPA: 4.0",
          "Minor in Cognitive Science (ask me about it!)"]} emph={-1} phrase="lists" />
      </div>
    );
  }
}
/*
<img className="school" src="/images/CWRU-ENG-Logo-white-rev.svg" alt="Case Western Reserve University"></img>
          <br></br>
*/


/**
 * Experience component of the home page which uses animaiton and typein for arrival 
 */
class Experience extends Component {
  render() {
    return (
      <div className="edu">
        <Typein texts={["Experience"]} phrase="notList" />
        <Typein texts={["IBM, Poughkeepsie, NY",
          "Backend Z Software Engineer",
          "Developing code to accelerate the development of Z systems",
          "Agile workplace working in sprint segments",
          "July 2021"]} phrase="lists" emph={0} />
        <br></br>
        <Typein texts={["IBM, Poughkeepsie, NY",
          "DevOps Backend Z Software Intern",
          "Implementing continuous development workflows for testing and deployment",
          "Agile Development setting, lead sprint segments and retroactives",
          "Scripting internal tool usage for migration to Git remote development platform",
          "Summer 2020"]} phrase="lists" emph={0} />
        <br></br>
        <Typein texts={["Fairfield County Bank, Ridgefield, CT",
          "Technology Intern",
          "Deploys and maintains cybersecurity, software, and hardware of the bank",
          "Programming and Scripting to assist with hardware deployment and custom software",
          "Assess technological needs of employees and possible security threats",
          "Summer 2019"]} phrase="lists" emph={0} />
        <br></br>
        <Typein texts={["Vaco SF in partnership with Google NYC",
          "Google Cloud Platform Student Innovator and Cloud Project Developer",
          "Engages with over 500 students teaching cloud services and deployment tools",
          "Manages $1000 budget for events with students, faculty, and employees",
          "2018-2019"] } emph={0} phrase="lists" />
      </div>
    );
  }
}
/*
<Anim paths={
          ["M362.71,359.31H108.1V550.06H405.72v86H.38V16.13H402.74V103H108.1V274.15H362.71Z",
            "M648.84,327l87.71-151.58h115L710.58,402.31,856.2,636.07H742.09l-92-157.54L558.57,636.07h-115L589.23,402.31,448.72,175.37H562.83Z",
            "M1325.41,410.4q0,106.88-48.54,170.53t-130.29,63.65q-75.8,0-121.35-49.81V813.19H921.77V175.37h95.37l4.26,46.84q45.55-55.35,123.9-55.35,84.3,0,132.21,62.8T1325.41,404Zm-103-8.94q0-69-27.47-109.42t-78.55-40.45q-63.45,0-91.12,52.37V508.33q28.1,53.66,92,53.65,49.38,0,77.28-39.81T1222.37,401.46Z",
            "M1615.79,644.58q-98.36,0-159.46-62t-61.1-165V404.87q0-69,26.62-123.27t74.72-84.51q48.11-30.24,107.3-30.23,94.1,0,145.4,60t51.31,169.89V438.5h-301q4.68,57.06,38.11,90.27T1621.75,562q71.1,0,115.81-57.48l55.78,53.22q-27.69,41.31-73.87,64.08T1615.79,644.58Zm-12.35-394.7q-42.59,0-68.76,29.81t-33.43,83h197.14v-7.66q-3.41-51.94-27.68-78.56T1603.44,249.88Z",
            "M2122,269.89a257.12,257.12,0,0,0-42.15-3.4q-71.11,0-95.8,54.5V636.07H1880.63V175.37h98.78l2.55,51.52q37.47-60,103.89-60,22.14,0,36.62,6Z",
            "M2187.61,55.73q0-23.85,15.12-39.6T2246,.38q28.1,0,43.43,15.75t15.32,39.6q0,23.42-15.32,39T2246,110.23q-28.11,0-43.22-15.54T2187.61,55.73Zm109.85,580.34H2194V175.37h103.46Z",
            "M2613,644.58q-98.35,0-159.46-62t-61.1-165V404.87q0-69,26.62-123.27t74.72-84.51q48.11-30.24,107.3-30.23,94.1,0,145.4,60t51.31,169.89V438.5h-301q4.68,57.06,38.11,90.27T2618.93,562q71.1,0,115.81-57.48l55.78,53.22q-27.69,41.31-73.87,64.08T2613,644.58Zm-12.35-394.7q-42.59,0-68.76,29.81t-33.43,83h197.14v-7.66q-3.42-51.94-27.68-78.56T2600.62,249.88Z",
            "M2974,175.37l3,53.22q51.09-61.72,134.12-61.73,143.91,0,146.47,164.77V636.07H3154.14V337.59q0-43.84-18.95-64.93t-62-21.07q-62.59,0-93.25,56.62V636.07H2876.53V175.37Z",
            "M3552.24,562q38.75,0,64.3-22.57t27.25-55.77h97.5q-1.71,43-26.83,80.26t-68.12,59q-43,21.71-92.82,21.71-96.66,0-153.28-62.59t-56.63-172.87V398.48q0-105.17,56.2-168.4t153.28-63.22q82.17,0,133.91,47.9t54.29,125.81h-97.5q-1.71-39.58-27-65.14t-64.51-25.55q-50.23,0-77.49,36.41t-27.68,110.49v16.6q0,75,27,111.77T3552.24,562Z",
            "M4022.3,644.58q-98.36,0-159.45-62t-61.1-165V404.87q0-69,26.61-123.27t74.73-84.51q48.1-30.24,107.29-30.23,94.1,0,145.41,60t51.3,169.89V438.5h-301q4.68,57.06,38.1,90.27t84.1,33.21q71.1,0,115.81-57.48l55.78,53.22Q4172.17,599,4126,621.8T4022.3,644.58ZM4010,249.88q-42.59,0-68.77,29.81t-33.42,83h197.14v-7.66q-3.42-51.94-27.68-78.56T4010,249.88Z"]
        } res1="4207.47" res2="813.57" word="Experience" />
  */

/**
 * Activities componend renders Activities which appears on home page with animation entrance and typein body 
 */
class Activities extends Component {
  render() {
    return (
      <div className="edu">
        <Anim paths={
          ["M402.91,491.73H162.77L112.52,636.07H.54L234.72,16.13h96.65L566,636.07H453.57ZM193,404.87H372.68L282.84,147.7Z",
            "M815.49,562q38.73,0,64.29-22.57T907,483.64h97.5q-1.69,43-26.82,80.26t-68.12,59q-43,21.71-92.82,21.71-96.66,0-153.29-62.59T606.86,409.12V398.48q0-105.17,56.2-168.4t153.28-63.22q82.17,0,133.91,47.9t54.28,125.81H907q-1.71-39.58-27-65.14t-64.5-25.55q-50.25,0-77.49,36.41T710.32,396.78v16.6q0,75,27,111.77T815.49,562Z",
            "M1212.32,63.39v112h81.32V252h-81.32V509.18q0,26.41,10.43,38.11T1260,559a159.2,159.2,0,0,0,36.19-4.26v80q-35.34,9.78-68.12,9.79-119.22,0-119.22-131.56V252h-75.79V175.37h75.79v-112Z",
            "M1372.84,55.73q0-23.85,15.11-39.6T1431.17.38q28.1,0,43.43,15.75t15.33,39.6q0,23.42-15.33,39t-43.43,15.54q-28.11,0-43.22-15.54T1372.84,55.73Zm109.85,580.34H1379.22V175.37h103.47Z",
            "M1757.74,504.93l97.51-329.56h106.87l-159.67,460.7h-89.84l-160.95-460.7H1659Z",
            "M2026.84,55.73q0-23.85,15.11-39.6T2085.17.38q28.1,0,43.43,15.75t15.33,39.6q0,23.42-15.33,39t-43.43,15.54q-28.11,0-43.22-15.54T2026.84,55.73Zm109.85,580.34H2033.22V175.37h103.47Z",
            "M2379,63.39v112h81.32V252H2379V509.18q0,26.41,10.43,38.11T2426.64,559a159.35,159.35,0,0,0,36.2-4.26v80q-35.36,9.78-68.13,9.79-119.22,0-119.22-131.56V252H2199.7V175.37h75.79v-112Z",
            "M2539.48,55.73q0-23.85,15.11-39.6T2597.81.38q28.09,0,43.43,15.75t15.33,39.6q0,23.42-15.33,39t-43.43,15.54q-28.11,0-43.22-15.54T2539.48,55.73Zm109.85,580.34H2545.86V175.37h103.47Z",
            "M2964.83,644.58q-98.35,0-159.45-62t-61.1-165V404.87q0-69,26.61-123.27t74.72-84.51q48.12-30.24,107.3-30.23,94.1,0,145.4,60t51.31,169.89V438.5h-301q4.68,57.06,38.11,90.27T2970.79,562q71.1,0,115.82-57.48l55.77,53.22q-27.68,41.31-73.87,64.08T2964.83,644.58Zm-12.35-394.7q-42.57,0-68.76,29.81t-33.42,83h197.13v-7.66q-3.41-51.94-27.67-78.56T2952.48,249.88Z",
            "M3488.12,510.89q0-27.67-22.78-42.16t-75.58-25.54q-52.8-11.07-88.14-28.1-77.49-37.47-77.49-108.58,0-59.61,50.24-99.63t127.74-40q82.59,0,133.48,40.87t50.88,106H3483q0-29.81-22.14-49.6t-58.76-19.8q-34.07,0-55.57,15.75T3325,302.25q0,23.85,20,37.05t80.9,26.61q60.89,13.41,95.59,31.93t51.52,44.5q16.82,26,16.82,63,0,62.17-51.52,100.7t-135,38.53q-56.64,0-100.91-20.44t-69-56.2q-24.7-35.76-24.7-77.07h100.49q2.13,36.63,27.67,56.42t67.7,19.8q40.88,0,62.17-15.54T3488.12,510.89Z"]
        } res1="3590.25" res2="644.96" word="Activities" />
        <Accord />
      </div>
    );
  }
}
/*
<Typein texts={["CWRU Crew, Rower (2018-Presenet) Men’s Team Captain(2020)",
          "Driven collegiate rower and organizer for men’s team competitions",
          "CWRU Hacker Society (2017-Present)", "Explores the applications of security and networking in today’s Internet of Things",
          "National Residence Hall Association, Residential College President //2017-2018",
          "Facilitated group discussions, interfaced with faculty, managed an over $2000 budget",
          "Acts of Random Kindness, Secretary", "Spreaking Kindness with the CWRU Community through acts small and large"]} phrase="lists" />
*/


/**
 * Exports multiple templates form one page
 */
export {
  Education,
  Experience,
  Activities
}
