import React, { Component } from 'react'
import './../Styling/Anim.css';
import Paths from './Paths'

/**
 * This requires props to be passed to it, these are the necessary and unique pieces of information from the SVG file
 * This class uses the helper function found in Paths.js which uses map function to dynamically generate the paths
 * The Anim.css file is crucial for this animation 
 * 
 * @param paths The paths in string form per character, commonly the d="this" attribute 
 * @param word A unique identifier for the animation, this will be used as a key, so this must be unique and one word no spaces or dashes
 * @param res1 The defining width of the object, is derived from viewbox="0 0 THIS x"
 * @param res2 The defining height of the object, it is derived from viewbox="0 0 x THIS"
 * @return The unique animation from the provided information wrapped into two divs 
 */
class Anim extends Component {
  /* Class Constructor */
  constructor(props) {
    super(props); 

    this.state = {anim: false};

    // Tracks the Reference to this element to know when is on page 
    this.myRef = React.createRef();

    this.handleScroll = this.handleScroll.bind(this);
  }
  
  // Handle on load essentially 
  componentDidMount() {
    // Listen for when it scrolls into view 
    window.addEventListener('scroll', this.handleScroll);

    // Check if is on screen on load
    this.handleScroll(); 
    
    // Find the path elements and configure the proper lengths of the animations 
    const logo = document.querySelectorAll(".logo." + this.props.word + " path");
    for (let i = 0; i < logo.length; i++) {
        logo[i].style.strokeDasharray = logo[i].getTotalLength();
        logo[i].style.strokeDashoffset = logo[i].getTotalLength();
    }
    if(this.props.word === "CWRU") {
      const logo2 = document.getElementById("oneRect");
      logo2.style.strokeDasharray = logo2.getTotalLength();
      logo2.style.strokeDashoffset = logo2.getTotalLength();
    }
  }

  // Done on umnount of the page
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
      // height displayed by the screen
      const screenHeight = window.innerHeight;

      // Between the outer div and the top of the screen
      const distToTop = this.myRef.current.getBoundingClientRect().top;

      // check if it is on the screen
      const wiggle = screenHeight * .4
      if (distToTop + wiggle < screenHeight) {
          this.startAnimation();
          window.removeEventListener('scroll', this.handleScroll);
      }
  }

  startAnimation() {
    // Update the CSS to handle CSS side animations 
    this.setState({anim: true});

    let delay = 0.2;
    if(this.props.word === "CWRU") {
      delay = 0.05;
    }

    // Update all the letters with a staggared start to their animation 
    const letters = document.querySelectorAll("." + this.props.word + " path");
    for (let i = 0; i < letters.length; i++) {
        letters[i].style.animation = `line-anim 2s ease forwards ${i * delay}s`;
    }
  }

  // Renders the final graphic 
  render() {
    // Local variable instantiation can go here 
    let play = this.state.anim;

    return (
        <div className="demos" ref={this.myRef}>
            <svg className={`logo ${this.props.word} ${play ? "logo-anim" : "" }`} xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${this.props.res1} ${this.props.res2}`}>
                <Paths paths={this.props.paths} word={this.props.word}/>
            </svg>
        </div>
    );
  }
}

export default Anim;