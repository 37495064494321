import React from 'react';
import './../Styling/Monitor.css';

/**
 * Renders the screen part of the monitor depending on the screen asked
 */
function MonitorRend() {
    // If Mobile Device
    if (window.innerHeight > window.innerWidth) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1125 2436" className="comp" preserveAspectRatio="none">
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path className="cls-1a" d="M0,0V2436H1125V0ZM1077,2014.58A143.42,143.42,0,0,1,933.58,2158H203.42A143.42,143.42,0,0,1,60,2014.58V291.42A143.42,143.42,0,0,1,203.42,148H933.58A143.42,143.42,0,0,1,1077,291.42Z" />
                        <path className="cls-2" d="M1051.63,31H75.37A59.37,59.37,0,0,0,16,90.37V2345.91a59.37,59.37,0,0,0,59.37,59.37h976.26a59.37,59.37,0,0,0,59.37-59.37V90.37A59.37,59.37,0,0,0,1051.63,31ZM1077,2014.58A143.42,143.42,0,0,1,933.58,2158H203.42A143.42,143.42,0,0,1,60,2014.58V291.42A143.42,143.42,0,0,1,203.42,148H933.58A143.42,143.42,0,0,1,1077,291.42Z" />
                        <path className="cls-1a" d="M38,120V2174.83H1096V120ZM1077,2014.58A143.42,143.42,0,0,1,933.58,2158H203.42A143.42,143.42,0,0,1,60,2014.58V291.42A143.42,143.42,0,0,1,203.42,148H933.58A143.42,143.42,0,0,1,1077,291.42Z" />
                        <rect className="cls-1a" x="72.12" y="2216.35" width="142.64" height="142.64" />
                        <path className="cls-2" d="M90.66,2293.21l16.46,11.13v15.51l-24.87-20.22v-13.07l24.87-20.22v15.51Z" />
                        <path className="cls-2" d="M131.49,2246.79h8.67v55.84a47.1,47.1,0,0,1-1.78,13.6,21.13,21.13,0,0,1-5,9.11,10.13,10.13,0,0,1-7.31,3.21q-6.66,0-10.39-6.51t-3.73-18.42h8.72c0,4,.44,6.87,1.3,8.76a4.3,4.3,0,0,0,4.1,2.82q2.49,0,4-3.27t1.47-9.3Z" />
                        <path className="cls-2" d="M172.88,2292.49H156.24v21.6h19.53v13.35H147.58v-80.65h28.13v13.46H156.24v19.22h16.64Z" />
                        <path className="cls-2" d="M197.34,2293l-16.78-11.24v-15.4l25.15,20.22v13l-25.15,20.28v-15.46Z" />
                        <path className="cls-3" d="M890.06,2249.49a6.11,6.11,0,0,1,6.11-6c128.88,0-7.3-.15,135.27-.15,2.2,0,16.54,2.64,23.32,11.32a33.89,33.89,0,0,1,5.12,9.79,29.45,29.45,0,0,1,1.26,8.6v70.67c-.05,2.38-.55,13.14-9.15,21.44a30,30,0,0,1-13.56,7.43,5.79,5.79,0,0,1-1.38.17l-122,.56A23,23,0,0,1,892,2350.75q-.15-8.61-.29-17.22a6,6,0,0,0-6-5.84H458.29a21.67,21.67,0,0,1-15-6.21,21.14,21.14,0,0,1-6.21-15v-9.59a27.16,27.16,0,0,1,27.16-27.16H883.87a6,6,0,0,0,6-5.84Z" />
                        <rect className="cls-4" x="1007.54" y="2340.67" width="8.48" height="9.19" />
                        <rect className="cls-5" x="482.2" y="2287.12" width="533.83" height="21.21" />
                    </g>
                </g>
            </svg>
        );
    }
    // If desktop device
    else {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7680 4144.48" className="comp" preserveAspectRatio="none">
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path className="cls-1a" d="M0,0V4144.48H7680V0ZM7361.74,3417.23a143.42,143.42,0,0,1-143.42,143.42H461.68a143.42,143.42,0,0,1-143.42-143.42V367A143.42,143.42,0,0,1,461.68,223.61H7218.32A143.42,143.42,0,0,1,7361.74,367Z" />
                        <path className="cls-2" d="M7483.94,98.39H196.06a59.36,59.36,0,0,0-59.36,59.37V3934.85a59.36,59.36,0,0,0,59.36,59.37H7483.94a59.36,59.36,0,0,0,59.36-59.37V157.76A59.36,59.36,0,0,0,7483.94,98.39Zm-122.2,3318.84a143.42,143.42,0,0,1-143.42,143.42H461.68a143.42,143.42,0,0,1-143.42-143.42V367A143.42,143.42,0,0,1,461.68,223.61H7218.32A143.42,143.42,0,0,1,7361.74,367Z" />
                        <path className="cls-1a" d="M235.09,186V3629.52H7447.61V186ZM7361.74,3417.23a143.42,143.42,0,0,1-143.42,143.42H461.68a143.42,143.42,0,0,1-143.42-143.42V367A143.42,143.42,0,0,1,461.68,223.61H7218.32A143.42,143.42,0,0,1,7361.74,367Z" />
                        <rect className="cls-1a" x="593.83" y="3714.97" width="236.84" height="236.84" />
                        <path className="cls-2" d="M624.62,3842.59,652,3861.08v25.75l-41.28-33.57v-21.71L652,3798v25.75Z" />
                        <path className="cls-2" d="M692.43,3765.51h14.38v92.72a78.34,78.34,0,0,1-2.95,22.58q-2.94,9.8-8.32,15.13a16.81,16.81,0,0,1-12.13,5.33q-11.07,0-17.26-10.8T660,3859.88h14.48q0,9.84,2.16,14.54c1.44,3.12,3.71,4.69,6.81,4.69q4.13,0,6.57-5.43t2.45-15.45Z" />
                        <path className="cls-2" d="M761.14,3841.39H733.52v35.88h32.42v22.16h-46.8V3765.51h46.7v22.35H733.52v31.92h27.62Z" />
                        <path className="cls-2" d="M801.76,3842.31l-27.86-18.67v-25.57l41.76,33.57v21.62l-41.76,33.67v-25.67Z" />
                        <path className="cls-3" d="M6609.49,3682a10.15,10.15,0,0,1,10.14-10c214,0-12.11-.25,224.61-.25,3.66,0,27.47,4.39,38.73,18.8a56.19,56.19,0,0,1,8.49,16.25,49.15,49.15,0,0,1,2.11,14.28v117.35c-.09,3.95-.92,21.82-15.21,35.6a49.69,49.69,0,0,1-22.5,12.33,9.4,9.4,0,0,1-2.3.28l-202.55.94a38.14,38.14,0,0,1-38.31-37.48q-.26-14.3-.49-28.59a9.88,9.88,0,0,0-9.87-9.7H5892.57a36,36,0,0,1-24.91-10.32,35.1,35.1,0,0,1-10.31-24.9V3760.7a45.09,45.09,0,0,1,45.09-45.09h696.78a9.88,9.88,0,0,0,9.87-9.71Z" />
                        <rect className="cls-4" x="6818.65" y="3818.13" width="14.09" height="15.26" />
                        <rect className="cls-5" x="5932.27" y="3744.48" width="886.38" height="35.22" />
                    </g>
                </g>
            </svg>
        );
    }
}

export default MonitorRend