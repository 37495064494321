import React from 'react';
import Anim from './../Components/Helper/Anim'

/**
 * About Page Wrapper, relies on React Router for routing to here
 */
class About extends React.Component {
   render() {
      return (
         <div>
            <div style={{ textAlign: 'center', paddingTop: 60, paddingLeft: 60, paddingRight: 60 }}>
               <h1>About the Author</h1>
               <br></br>
               <div style={{color: 'white', fontSize: '1.1em'}}>
                  <p>This site is in development and while I'm still working on coding and making this look good, unfortunately writing about myself has fallen to the wayside.</p>
                  <p>But for a little information about me, I'm about to graduate from Case Western Reserve University (or maybe have already, I'm not sure when I will update this)
                      and I'm going to start working for IBM soon (or maybe I have also). I really enjoy programming, and especially learning new things. I've wanted to go into research 
                      many times, but working for IBM was an opportunity that I could not pass up. Cognitive Science was another area I studied when I was in school, and I really enjoyed it. 
                      I even wrote a piece about why more Computer Scientists should study Cognitive Science. I think there are a lot of frontiers of Computer Science that haven't been explored
                       yet, and I hope to explore some of them. A lot of Cognitive Science topics apply to robotics and machine learning. Both of which were areas I was interested, but proved 
                       difficult (luckily I like a challenge). I do my best to stay up to date on research of the topics so that I can keep up with what is going on. As soon as we can 
                       train unrestricted Boltzmann Machines, I'll be trying my hand at them. I think the math draws me into these topics, and it was the math behind encryption that led me to 
                       focus on Computer Security for my degree. I'd love to be a part of the teams that design the next generation of these things. I think that is why I have yet to be able to 
                       shake the idea of research, it just seems so interesting to be the one on the cutting edge. I'll keep trying in my free time, and I'll use the resources I have to keep learning. 
                  </p>
                  <p>I really like not programming as well. Rowing and Cycling get me outdoors and give me time to think; I really appreciate them. Cooking and Baking follows enough of 
                     a science that the engineer in me enjoys them, plus I like eating. I'd much rather be in the mountains than at a beach, but you could find me at either. I'm not sure 
                     where I'll end up in the long run, but I guess that's life. For now I'm going to keep trying new things, seeing if any of them stick. Web development is fun, but I think 
                     I enjoy making algorithms to do things instead of messing around with CSS all the time. Everyone has to suffer a little I guess. Hopefully I'll keep this going, it's nice 
                     to document progress and see old work.
                  </p>
                  <p>
                     The animation under this was when I was first testing it out to see if it would work. I wrote it myself and tried to make it a callable function. It worked out, and that's 
                     why you see it overused on the rest of the site. I don't have the heart to delete it quite yet, so it'll stick around as a relic. 
                  </p>
               </div>
            </div>
            <Anim paths={
               ["M3.5,12.08c27.72-4.62,64-8.58,110.22-8.58,56.76,0,98.34,13.2,124.74,37,24.42,21.12,38.94,53.46,38.94,93.06,0,40.26-11.88,71.94-34.32,95-30.36,32.34-79.86,48.84-136,48.84-17.16,0-33-.66-46.2-4v178.2H3.5Zm57.42,214.5c12.54,3.3,28.38,4.62,47.52,4.62,69.3,0,111.54-33.66,111.54-95C220,77.42,178.4,49,115,49,90,49,70.82,51,60.92,53.66Z",
                  "M342.08,231.86c0-37.62-.66-70-2.64-99.66h50.82l2,62.7h2.64c14.52-42.9,49.5-70,88.45-70a62.93,62.93,0,0,1,16.5,2V181.7a87.31,87.31,0,0,0-19.8-2c-40.93,0-70,31-77.89,74.58a163.47,163.47,0,0,0-2.64,27.06V451.64H342.08Z",
                  "M840.39,289.28c0,118.14-81.84,169.62-159.06,169.62-86.46,0-153.12-63.36-153.12-164.34,0-106.92,70-169.62,158.4-169.62C778.35,124.94,840.39,191.6,840.39,289.28ZM587,292.58c0,70,40.26,122.76,97,122.76,55.44,0,97-52.14,97-124.08,0-54.12-27.06-122.76-95.7-122.76S587,231.86,587,292.58Z",
                  "M1188.86,132.2c-1.32,23.1-2.64,48.84-2.64,87.78V405.44c0,73.26-14.52,118.14-45.54,145.86-31,29-75.9,38.28-116.16,38.28-38.28,0-80.52-9.24-106.26-26.4L932.78,519C953.9,532.16,986.9,544,1026.5,544c59.4,0,103-31,103-111.54V396.86h-1.32c-17.82,29.7-52.14,53.46-101.64,53.46-79.2,0-136-67.32-136-155.76,0-108.24,70.62-169.62,143.88-169.62,55.44,0,85.8,29,99.66,55.44h1.32L1138,132.2ZM1128.8,258.26c0-9.9-.66-18.48-3.3-26.4-10.56-33.66-38.94-61.38-81.18-61.38-55.44,0-95,46.86-95,120.78,0,62.7,31.68,114.84,94.38,114.84,35.64,0,68-22.44,80.52-59.4a101,101,0,0,0,4.62-31Z",
                  "M1278.62,231.86c0-37.62-.66-70-2.64-99.66h50.82l2,62.7h2.64c14.52-42.9,49.5-70,88.44-70a62.82,62.82,0,0,1,16.5,2V181.7a87.25,87.25,0,0,0-19.8-2c-40.92,0-70,31-77.88,74.58a163.47,163.47,0,0,0-2.64,27.06V451.64h-57.42Z",
                  "M1668,451.64l-4.62-40.26h-2c-17.82,25.08-52.14,47.52-97.68,47.52-64.68,0-97.68-45.54-97.68-91.74,0-77.22,68.64-119.46,192.06-118.8v-6.6c0-26.4-7.26-73.92-72.6-73.92-29.7,0-60.72,9.24-83.16,23.76l-13.2-38.28c26.4-17.16,64.68-28.38,104.94-28.38,97.68,0,121.44,66.66,121.44,130.68V375.08c0,27.72,1.32,54.78,5.28,76.56Zm-8.58-163c-63.36-1.32-135.3,9.9-135.3,71.94,0,37.62,25.08,55.44,54.78,55.44,41.58,0,68-26.4,77.22-53.46a60.62,60.62,0,0,0,3.3-18.48Z",
                  "M1809.26,218.66c0-33-.66-60.06-2.64-86.46h50.82l2.64,51.48h2c17.82-30.36,47.52-58.74,100.32-58.74,43.56,0,76.56,26.4,90.42,64h1.32a131.59,131.59,0,0,1,35.64-41.58c19.14-14.52,40.26-22.44,70.62-22.44,42.24,0,104.94,27.72,104.94,138.6v188.1h-56.76V270.8c0-61.38-22.44-98.34-69.3-98.34-33,0-58.74,24.42-68.64,52.8a95.65,95.65,0,0,0-4.62,29V451.64h-56.76V260.24c0-50.82-22.44-87.78-66.66-87.78-36.3,0-62.7,29-71.94,58.08-3.3,8.58-4.62,18.48-4.62,28.38V451.64h-56.76Z",
                  "M2359.7,218.66c0-33-.66-60.06-2.64-86.46h50.82l2.64,51.48h2c17.82-30.36,47.52-58.74,100.32-58.74,43.56,0,76.56,26.4,90.42,64h1.32a131.59,131.59,0,0,1,35.64-41.58c19.14-14.52,40.26-22.44,70.62-22.44,42.24,0,104.94,27.72,104.94,138.6v188.1H2759V270.8c0-61.38-22.44-98.34-69.3-98.34-33,0-58.74,24.42-68.64,52.8a95.65,95.65,0,0,0-4.62,29V451.64h-56.76V260.24c0-50.82-22.44-87.78-66.66-87.78-36.3,0-62.7,29-71.94,58.08-3.3,8.58-4.62,18.48-4.62,28.38V451.64H2359.7Z",
                  "M2942.48,302.48C2943.8,381,2994,413.36,3052,413.36c41.58,0,66.66-7.26,88.44-16.5l9.9,41.58c-20.46,9.24-55.44,19.8-106.26,19.8-98.34,0-157.08-64.68-157.08-161s56.76-172.26,149.82-172.26c104.28,0,132,91.74,132,150.48a219.06,219.06,0,0,1-2,27.06Zm170.28-41.58c.66-37-15.18-94.38-80.52-94.38-58.74,0-84.48,54.12-89.1,94.38Z",
                  "M3240.79,231.86c0-37.62-.66-70-2.64-99.66H3289l2,62.7h2.64c14.52-42.9,49.5-70,88.44-70a62.82,62.82,0,0,1,16.5,2V181.7a87.25,87.25,0,0,0-19.8-2c-40.92,0-70,31-77.88,74.58a163.47,163.47,0,0,0-2.64,27.06V451.64h-57.42Z"]
            } res1="3402.03" res2="593.08" word="Programmer" />
         </div>
      )
   }
}
export default About;