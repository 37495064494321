import React, { Component } from 'react'
import './../Styling/Monitor.css';

/**
 * Class that generates the keyboard component of the monitor 
 */
class KeyboardRend extends Component {
    constructor(props) {
        super(props);

        // Configure the state for the class
        this.state = {
            elem: null,
            playing: false
        };

        this.audio = this.audio.bind(this);
    }

    // On mount, update size and add listener for future changes 
    componentDidMount() {
        var test = document.getElementsByClassName("cls-5key");
        for (let i = 0; i < test.length; i++) {
            test[i].onclick = function () {
                if (test[i].className.baseVal.includes("spacebar")) {
                    console.log("Spacebar was clicked");
                    //this.audio();
                }
            }
        }
    }

    audio() {
        if (this.state.playing) {
            this.state.elem.pause();
            this.setState({playing: false });
        }
        else {
            let myAud = new Audio('/resources/Lament_Golden_Light.mp3');
            myAud.volume = 0.20;
            myAud.play();
            this.setState({ elem: myAud, playing: true });
        }
    }

    render() {
        // If it is being displayed on a mobile display, use the tall svg file
        if (window.innerHeight > window.innerWidth) {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1125 658.83">
                    <g id="Layer_2" data-name="Layer 2">
                        <g id="Layer_1-2" data-name="Layer 1">
                            <rect className="cls-1key" width="1125" height="658.83" />
                            <rect className="cls-2key" x="55" y="51.59" width="1015" height="72.26" rx="25.89" />
                            <rect className="cls-2key" x="56" y="166.12" width="1015" height="72.26" rx="25.89" />
                            <rect className="cls-3key" x="63" y="280.37" width="1001" height="358.47" rx="101.79" />
                            <rect className="cls-4key" x="160.02" y="349.83" width="98" height="98" />
                            <rect className="cls-5key" x="160.02" y="332.46" width="98" height="98" />
                            <rect className="cls-4key" x="302.18" y="349.83" width="98" height="98" />
                            <rect className="cls-5key" x="302.18" y="332.56" width="98" height="98" />
                            <rect className="cls-4key" x="445.23" y="350.34" width="98" height="98" />
                            <rect className="cls-5key" x="445.23" y="332.97" width="98" height="98" />
                            <rect className="cls-4key" x="587.38" y="350.34" width="98" height="98" />
                            <rect className="cls-5key" x="587.38" y="333.07" width="98" height="98" />
                            <rect className="cls-4key" x="727.23" y="350.34" width="98" height="98" />
                            <rect className="cls-5key" x="727.23" y="332.97" width="98" height="98" />
                            <rect className="cls-4key" x="869.38" y="350.34" width="98" height="98" />
                            <rect className="cls-5key" x="869.38" y="333.07" width="98" height="98" />
                            <rect className="cls-4key" x="158.62" y="490.09" width="98" height="98" />
                            <rect className="cls-5key" x="158.62" y="472.72" width="98" height="98" />
                            <rect className="cls-4key" x="300.78" y="490.09" width="98" height="98" />
                            <rect className="cls-5key" x="300.78" y="472.81" width="98" height="98" />
                            <rect className="cls-4key" x="443.83" y="490.6" width="98" height="98" />
                            <rect className="cls-5key" x="443.83" y="473.23" width="98" height="98" />
                            <rect className="cls-4key" x="587" y="490.6" width="378.98" height="98" />
                            <rect className="cls-5key spacebar" x="587" y="473.33" width="378.98" height="98" onClick={this.audio} />
                        </g>
                    </g>
                </svg>
            );
        }
        // If it is a web component, use the full svg file
        else {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7680 1875.13">
                    <g id="Layer_2" data-name="Layer 2">
                        <g id="Layer_1-2" data-name="Layer 1">
                            <rect className="cls-1key" width="7680" height="1875.13" />
                            <rect className="cls-2key" x="163.09" y="57.26" width="7331.48" height="72.26" rx="25.89" />
                            <rect className="cls-2key" x="163.09" y="186.78" width="7331.48" height="72.26" rx="25.89" />
                            <rect className="cls-3key" y="331.3" width="7680" height="1471.83" rx="202.28" />
                            <rect className="cls-4key" x="826.28" y="499.56" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="826.28" y="452.87" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="1208.46" y="499.56" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="1208.46" y="453.13" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="1590.11" y="499.56" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="1590.11" y="452.87" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="1978.54" y="499.56" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="1978.54" y="453.13" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="2354.07" y="499.56" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="2354.07" y="452.87" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="2736.24" y="499.56" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="2736.24" y="453.13" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="3117.89" y="499.56" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="3117.89" y="452.87" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="3506.33" y="499.56" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="3506.33" y="453.13" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="3887.85" y="499.82" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="3887.85" y="453.13" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="4270.02" y="499.82" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="4270.02" y="453.39" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="4651.68" y="499.82" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="4651.68" y="453.13" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="5040.11" y="499.82" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="5040.11" y="453.39" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="5415.63" y="499.82" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="5415.63" y="453.13" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="5797.8" y="499.82" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="5797.8" y="453.39" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="6179.46" y="499.82" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="6179.46" y="453.13" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="6567.89" y="499.82" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="6567.89" y="453.39" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="826.28" y="862.7" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="826.28" y="816" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="1208.46" y="862.7" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="1208.46" y="816.26" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="1590.11" y="862.7" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="1590.11" y="816" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="1978.54" y="862.7" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="1978.54" y="816.26" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="2354.07" y="862.7" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="2354.07" y="816" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="2736.24" y="862.7" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="2736.24" y="816.26" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="3117.89" y="862.7" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="3117.89" y="816" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="3506.33" y="862.7" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="3506.33" y="816.26" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="3887.85" y="862.96" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="3887.85" y="816.26" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="4270.02" y="862.96" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="4270.02" y="816.52" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="4651.68" y="862.96" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="4651.68" y="816.26" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="5040.11" y="862.96" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="5040.11" y="816.52" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="5415.63" y="862.96" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="5415.63" y="816.26" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="5797.8" y="862.96" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="5797.8" y="816.52" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="6179.46" y="862.96" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="6179.46" y="816.26" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="6567.89" y="862.96" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="6567.89" y="816.52" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="826.28" y="1226.87" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="826.28" y="1180.17" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="1208.46" y="1226.87" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="1208.46" y="1180.43" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="1590.11" y="1226.87" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="1590.11" y="1180.17" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="1978.54" y="1226.87" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="1978.54" y="1180.43" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="2354.07" y="1226.87" width="2561.09" height="263.48" />
                            <rect className="cls-5key spacebar" x="2354.07" y="1180.17" width="2561.09" height="263.48" onClick={this.audio}/>
                            <rect className="cls-4key" x="5040.11" y="1227.13" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="5040.11" y="1180.69" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="5415.63" y="1227.13" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="5415.63" y="1180.43" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="5797.8" y="1227.13" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="5797.8" y="1180.69" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="6179.46" y="1227.13" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="6179.46" y="1180.43" width="263.48" height="263.48" />
                            <rect className="cls-4key" x="6567.89" y="1227.13" width="263.48" height="263.48" />
                            <rect className="cls-5key" x="6567.89" y="1180.69" width="263.48" height="263.48" />
                        </g>
                    </g>
                </svg>
            );
        }
    }
}

export default KeyboardRend