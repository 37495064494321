import React, { Component } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import './../Styling/Accord.css'

/**
 * Generates the accordian for ues wherever, Bootstrap
 */
class Accord extends Component {
    render() {
        return (
            <div className="accord">
                <Accordion defaultActiveKey="0" >
                    <Card className="border-0">
                        <Card.Header className="text-center">
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                CWRU Crew
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                             <p><i>Men's Team Captain, 2020; PR Chair, 2019; Collegiate Rower, 2018-Present</i></p>
                             <p>Driven collegiate rower and organizer for men’s team competitions</p>
                             <p>Hired new coach for the club, working with School and Alumni Association</p>
                             <p>Fosters team culture of respect and community amongst entire team on and off the water</p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card className="border-0">
                        <Card.Header className="text-center">
                            <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                CWRU Hacker Society
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                             <p><i>Community Member and Mentor, 2017-Present</i></p>
                             <p>Explores the applications of security and networking in today’s Internet of Things</p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card className="border-0">
                        <Card.Header className="text-center">
                            <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                Acts of Random Kindness
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                            <Card.Body>
                                <p><i>Secretary, 2020, CWRU ARK</i></p>
                                <p>Spreading Kindness with the CWRU Community through acts small and large</p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card className="border-0">
                        <Card.Header className="text-center">
                            <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                National Residence Hall Association
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                             <p><i>Residential College President, Magnolia & Carlton Road, 2017-2018</i></p>
                             <p>Facilitated group discussions, interfaced with faculty, managed an over $2000 budget</p>
                             </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card className="border-0">
                        <Card.Header className="text-center">
                            <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                Veterans Affairs Hospital
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="4">
                            <Card.Body>
                                <p><i>Mobility Department of the Cleveland VA, 2019-Present</i></p>
                                <p>Assist disabled veterans with mobility workouts to preserve muscle strength</p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        );
    }
}

export default Accord;