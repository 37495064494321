import React from 'react';

/**
 * Not sure if it will last forever, but is the root of the Contact page of this react project
 */
class Contact extends React.Component {
   render() {
      return (
         <div style={{textAlign: 'center', paddingTop: 60, paddingBottom: 120, paddingLeft: 60, paddingRight: 60}}>
            <h1>Projects...</h1>
            <div style={{color: 'white'}}>
            <p>Hopefully this section will fill up with explanations of the projects found under the drop down. One of the main goals is to document my process of learning Linear 
               Algebra on this site, so we will see if that happens. I also want to explain how modern websites work in an interactive and intuitive way, so we will see if I am able to 
               achieve that. For right now, I will just claim that idea, no one on the internet can take it (people have probably already done it better than I will ever be able to, but hey 
               at least I'll learn). Maybe I'll also put some of my Active Learning methods in here? That will require some conversion from Python to Javascript and no sklearn which would be a
                challenge. So we shall see, we shall see...
            </p>
            <p>
               Currently, the only projects you'll see and encryption and hashing algorithms that I wrote from my Computer Security class. They are pretty rudimentary, but I thought since import I
                wrote them and they were already in JavaScript, they couldn't be of any harm. They'll probably get shuffled down in the order as I add more impressive stuff, but we will see.
            </p>
            <p>
               I also need to add more links to the resources I used for these projects, both for proper credit and better SEO if I'm being honest (but what traffic would I expect to get anyway?).
            </p>
            <p>Now I'm just trying to take up space to hide the ugly footer hovering above the bottom of the page.</p>
            <br></br><br></br>
            </div>
         </div>
      )
   }
}
export default Contact;