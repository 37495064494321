import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home'
import About from './Pages/About'
import Contact from './Pages/Contact'
import Projects from './Pages/Projects'
import MD5 from './Pages/md5'
import Vigenere from './Pages/vigeneres'
import Nav from './Components/Nav'
import Alert from './Components/Alert'
import Footer from './Components/Footer'

class App extends Component {

  render() {
    return (
      <div className="App">
        <Alert />
        <Nav />
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
          <Route path="/projects" component={Projects} />
          <Route path="/md5" component={MD5} />
          <Route path="/vigenere" component={Vigenere} />
          <Route component={Error} />
        </Switch>
        <Footer />
      </div>
    );
  }
}

export default App;